import { CssBaseline } from '@mui/material';
import { RegisterAppUpdate } from '@price-for-profit/drive-app-core';
import { useTrackLogin } from '@price-for-profit/auth';
import { AppRoutes } from 'routing';

export default function App() {
    useTrackLogin({});
    return (
        <>
            <CssBaseline />
            <RegisterAppUpdate />
            <AppRoutes />
        </>
    );
}
